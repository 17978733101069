import React, { createContext, useContext, useEffect, useState } from "react";

// const myCustomQueries = {
//   xsmall: "screen and (max-width: 360px)",
//   xlsmall: "screen and (max-width: 480px)",
//   small: "screen and (max-width: 768px)",
//   medium: "screen and (max-width: 992px)",
//   large: "screen and (max-width: 1280px)",
//   xlarge: "screen and (min-width: 1281px)",
// };

export type Queries = {
  xsmall: string;
  xlsmall: string;
  small: string;
  medium: string;
  large: string;
  xlarge: string;
  [key: string]: string;
};

interface BreakpointProviderProps {
  queries: Queries;
}

const defaultValue = {};

const BreakpointContext = createContext(defaultValue);

const BreakpointProvider = ({
  children,
  queries,
}: React.PropsWithChildren<BreakpointProviderProps>) => {
  const [queryMatch, setQueryMatch] = useState({});

  useEffect(() => {
    const mediaQueryLists: any = {};
    const keys = Object.keys(queries);
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc, media) => {
        acc[media] = !!(
          mediaQueryLists[media] && mediaQueryLists[media].matches
        );
        return acc;
      }, {} as any);
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches: any = {};
      keys.forEach((media) => {
        if (typeof queries[media] === "string") {
          mediaQueryLists[media] = window.matchMedia(queries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach((media) => {
        if (typeof queries[media] === "string") {
          mediaQueryLists[media].addListener(handleQueryListener);
        }
      });
    }

    return () => {
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof queries[media] === "string") {
            mediaQueryLists[media].removeListener(handleQueryListener);
          }
        });
      }
    };
  }, [queries]);

  return (
    <BreakpointContext.Provider value={queryMatch}>
      {children}
    </BreakpointContext.Provider>
  );
};

function useBreakpoint() {
  const context = useContext(BreakpointContext);
  if (context === defaultValue) {
    throw new Error("useBreakpoint must be used within BreakpointProvider");
  }
  return context as Queries;
}

export { BreakpointContext, BreakpointProvider, useBreakpoint };
