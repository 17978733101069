/* eslint-disable no-unused-vars */
import * as React from "react";

export type Mode = "dark" | "light";
type State = {
  mode: Mode;
  beforeMode: Mode;
};

type Action =
  | { type: "CHANGE_MODE"; payload: { mode: Mode } }
  | { type: "CHANGE_MODE_TO_BEFORE" };

type Dispatch = React.Dispatch<Action>;

const ModeStateContext = React.createContext<State | null>(null);
const ModeDispatchContext = React.createContext<Dispatch | null>(null);

const ModeReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "CHANGE_MODE":
      return { ...state, mode: action.payload.mode, beforeMode: state.mode };
    case "CHANGE_MODE_TO_BEFORE":
      return { ...state, mode: state.beforeMode };
    default:
      throw new Error("Unhandled action");
  }
};

const ModeProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(ModeReducer, {
    mode: "light",
    beforeMode: "light",
  });

  return (
    <ModeStateContext.Provider value={state}>
      <ModeDispatchContext.Provider value={dispatch}>
        {children}
      </ModeDispatchContext.Provider>
    </ModeStateContext.Provider>
  );
};

export const useModeState = () => {
  const state = React.useContext(ModeStateContext);
  if (!state) throw new Error("Cannot find ModeProvider");
  return state;
};

export const useModeDispatch = () => {
  const dispatch = React.useContext(ModeDispatchContext);
  if (!dispatch) throw new Error("Cannot find ModeProvider");
  return dispatch;
};

export default ModeProvider;
