const canGtag = () => {
  return typeof window !== "undefined" && window.gtag;
};

interface GtagClickEvent {
  eventName: string;
  [key: string]: string;
}

interface GtagOpenModalEvent {
  eventName: string;
  [key: string]: string;
}

interface GtagShareEvent {
  eventName: string;
  [key: string]: string;
}

export const gtag = {
  click: ({ eventName, ...rest }: GtagClickEvent) => {
    if (canGtag()) {
      window.gtag("event", eventName, rest);
    }
  },
  openModal: ({ eventName, ...rest }: GtagOpenModalEvent) => {
    if (canGtag()) {
      window.gtag("event", eventName, rest);
    }
  },
  share: ({ eventName, ...rest }: GtagShareEvent) => {
    if (canGtag()) {
      window.gtag("event", eventName, rest);
    }
  },
};
