/* eslint-disable no-unused-vars */
import * as React from "react";

type State = {
  isVisible: boolean;
};

type Action = { type: "CHANGE_VISIBLE"; payload: boolean };

type Dispatch = React.Dispatch<Action>;

const ShareStateContext = React.createContext<State | null>(null);
const ShareDispatchContext = React.createContext<Dispatch | null>(null);

const ShareReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "CHANGE_VISIBLE":
      return { isVisible: action.payload };
    default:
      throw new Error("Unhandled action");
  }
};

const ShareProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(ShareReducer, {
    isVisible: false,
  });

  return (
    <ShareStateContext.Provider value={state}>
      <ShareDispatchContext.Provider value={dispatch}>
        {children}
      </ShareDispatchContext.Provider>
    </ShareStateContext.Provider>
  );
};

export const useShareState = () => {
  const state = React.useContext(ShareStateContext);
  if (!state) throw new Error("Cannot find ShareProvider");
  return state;
};

export const useShareDispatch = () => {
  const dispatch = React.useContext(ShareDispatchContext);
  if (!dispatch) throw new Error("Cannot find ShareProvider");
  return dispatch;
};

export default ShareProvider;
