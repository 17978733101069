/* eslint-disable no-unused-vars */
import * as React from "react";

type LottieStatus = "complete" | "play" | "load" | "error" | "init";
type State = {
  textLottieStatus: LottieStatus;
};

type Action = { type: "CHANGE_LOTTIE_STATUS"; payload: LottieStatus };

type Dispatch = React.Dispatch<Action>;

const IntroSectionStateContext = React.createContext<State | null>(null);
const IntroSectionDispatchContext = React.createContext<Dispatch | null>(null);

const IntroSectionReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "CHANGE_LOTTIE_STATUS":
      return { textLottieStatus: action.payload };
    default:
      throw new Error("Unhandled action");
  }
};

const IntroSectionProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(IntroSectionReducer, {
    textLottieStatus: "init",
  });

  return (
    <IntroSectionStateContext.Provider value={state}>
      <IntroSectionDispatchContext.Provider value={dispatch}>
        {children}
      </IntroSectionDispatchContext.Provider>
    </IntroSectionStateContext.Provider>
  );
};

export const useIntroSectionState = () => {
  const state = React.useContext(IntroSectionStateContext);
  if (!state) throw new Error("Cannot find IntroSectionProvider");
  return state;
};

export const useIntroSectionDispatch = () => {
  const dispatch = React.useContext(IntroSectionDispatchContext);
  if (!dispatch) throw new Error("Cannot find IntroSectionProvider");
  return dispatch;
};

export default IntroSectionProvider;
