/* eslint-disable no-unused-vars */
import * as Toast from "@radix-ui/react-toast";
import { ToastProvider as RadixProvider } from "@radix-ui/react-toast";
import * as React from "react";

import { toastRoot } from "../styles/toast.css";
import { useModeState } from "./ModeContext";

type State = {
  toastOpen: boolean;
  setToastOpen: React.Dispatch<React.SetStateAction<boolean>>;
  openToast: () => void;
};

const ToastContext = React.createContext<State | null>(null);

const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toastOpen, setToastOpen] = React.useState(false);
  const timerRef = React.useRef(0);

  const openToast = () => {
    setToastOpen(false);
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      setToastOpen(true);
    }, 100);
  };

  React.useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  return (
    <RadixProvider>
      <ToastContext.Provider value={{ toastOpen, setToastOpen, openToast }}>
        {children}
      </ToastContext.Provider>
    </RadixProvider>
  );
};
export const useToast = () => {
  const dispatch = React.useContext(ToastContext);
  if (!dispatch) throw new Error("Cannot find ToastProvider");
  return dispatch;
};

// NOTE: 현재는 링크 복사 토스트만 있음
export const ToastComponent = () => {
  const { toastOpen, setToastOpen } = useToast();
  const { mode } = useModeState();

  return (
    <Toast.Root
      className={toastRoot({ mode })}
      open={toastOpen}
      onOpenChange={setToastOpen}
      duration={1500}
    >
      <Toast.Title>링크가 복사됐어요!</Toast.Title>
      <Toast.Close />
    </Toast.Root>
  );
};

export default ToastProvider;
