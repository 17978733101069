/* eslint-disable no-unused-vars */
import * as React from "react";

import { gtag } from "../utils/gtag";

type Content = "campaign" | "promotion-qr" | "play-store-qr" | "app-store-qr";
type State = {
  isOpen: boolean;
  content: Content;
};

type Action =
  | { type: "OPEN"; payload: { target: Content } }
  | { type: "CHANGE_TO"; payload: { target: Content; open: boolean } };

type Dispatch = React.Dispatch<Action>;

const ModalStateContext = React.createContext<State | null>(null);
const ModalDispatchContext = React.createContext<Dispatch | null>(null);

const modalReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "OPEN":
      gtag.openModal({
        eventName: `open_${action.payload.target}_modal`,
      });
      return { isOpen: true, content: action.payload.target };
    case "CHANGE_TO":
      if (action.payload.open) {
        gtag.openModal({
          eventName: `open_${action.payload.target}_modal`,
        });
      }
      return {
        isOpen: action.payload.open,
        content: action.payload.target,
      };
    default:
      throw new Error("Unhandled action");
  }
};

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(modalReducer, {
    isOpen: false,
    content: "campaign",
  });

  /*
   ** NOTE: 안드로이드 유저들은 뒤로가기 버튼을 눌러서 모달을 닫는 경향이 있어서
   ** 안드로이드에서 뒤로가기 버튼을 눌렀을 때 모달이 닫히도록 함
   */
  React.useEffect(() => {
    if (state.isOpen) {
      window.history.pushState(null, "", window.location.pathname);
    } else {
      if (window.history.state === null) return;
      window.history.back();
    }

    const onPopState = () => {
      dispatch({
        type: "CHANGE_TO",
        payload: { target: "campaign", open: false },
      });
    };

    window.addEventListener("popstate", onPopState);

    return () => {
      window.removeEventListener("popstate", onPopState);
    };
  }, [state.isOpen]);

  return (
    <ModalStateContext.Provider value={state}>
      <ModalDispatchContext.Provider value={dispatch}>
        {children}
      </ModalDispatchContext.Provider>
    </ModalStateContext.Provider>
  );
};

export const useModalState = () => {
  const state = React.useContext(ModalStateContext);
  if (!state) throw new Error("Cannot find ModalProvider");
  return state;
};

export const useModalDispatch = () => {
  const dispatch = React.useContext(ModalDispatchContext);
  if (!dispatch) throw new Error("Cannot find ModalProvider");
  return dispatch;
};

export default ModalProvider;
